<template>
    <v-container fluid class="success-wrapper fill-height">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="10" md="8" lg="6" class="px-5 my-5">
                <v-card class="mx-auto" max-width="480">
                    <v-img class="white--text align-end" height="320px" src="../assets/banner/cancel.jpg">
                        <v-card-title class="success-title">Pagamento Cancelado</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">Você não completou seu cadastro</v-card-subtitle>
                    <v-card-text class="text--primary">
                        <div class="body-2 my-4 text-justify">Caso deseje completar seu cadastro, entre em contato com o suporte ou nos chame no Whatsapp.</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'cancelled',
}
</script>

<style lang="scss">
.success-title {
    background-color: rgba(0, 0, 0, .75);
}

.success-wrapper {
    background-image: radial-gradient(rgb(0, 0, 0, .1) 40%, rgb(0, 0, 0, .9)), url(../assets/slides/3.jpg);
    background-position: center;
    background-size: cover;
}
</style>